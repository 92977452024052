/* Common styles for both light and dark themes */
html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: var(--background);
  overflow-x: hidden;
}

:link {
  text-decoration: none;
  color: var(--font-s);
  display: block;
}

.App {
  flex: 1; 
  display: flex;
  flex-direction: column; 
}

.pages {
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  padding: 10px 0 10px 0; 
  text-align: center; 
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1); 
  width: 100%; 
  background-color: var(--background);
  color: var(--font-s);
  z-index: 10001;
}

footer p {
  margin: 0; 
}

h2 {
  text-align: center;
}

.social {
  text-align: center;
  margin: auto;
  padding: 20% 0;
}

.social i {
  margin: auto;
  text-align: center;
  padding: 0 2%;
}