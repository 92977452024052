/* header.css */
header {
    display: grid; 
    grid-template-columns: auto 1fr auto;
    align-items: center;
    padding: 10px; 
    text-align: center; 
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
    width: 100%; 
    box-sizing: border-box;
    background-color: var(--background);
    color: var(--font-s);
    gap: 10px;
    overflow-y: hidden;
}

header img {
    width: 50px;
    height: 50px;
    justify-self: start;
}
  
header h1 {
    margin: 0; 
    font-size: 1.5em;
    text-align: center;
    justify-self: center;
    z-index: 51;
}

header i {
    justify-self: end;
    cursor: pointer;
    transition: transform 0.3s ease-in-out; /* Smooth transition for icon toggle */
}

.fa-bars {
    transform: rotate(0deg);
    color: var(--font-s);
    /* if z index = 51 so the animation is preety... menu close brakes*/
}

.fa-x {
    transform: rotate(180deg); /* Rotate the X icon for a smoother effect */
    color: var(--font-s);
    z-index: 51;
}
