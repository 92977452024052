.main-box {
  margin: auto;
  background-color: var(--primary);
  width: 100%;
  height: 100%;
  border-radius: 8px;
  flex: 1;
}

h2 {
  text-align: center;
  margin: 0px;
  padding: 35px 0 20px 0;
}

.contact-form ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

.contact-form li {
  margin-bottom: 15px;
  text-align: center;
}

.contact-form label {
  display: block;
  font-size: 1em;
  font-weight: bold;
}

.contact-form input {
  width: 25ch;
  padding: 0.8ch;
  border-radius: 4px;
  font-size: 1em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease; 
}

.contact-form textarea {
  width: 100%;
  max-width: 75%;
  padding: 10px;
  border-radius: 4px;
  font-size: 1em;
  height: 15ch;
  margin: 4% 0 5% 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease;
}

.contact-form input,
.contact-form textarea {
  background-color: var(--accent);
  border: 1px solid var(--secondary);
}

input::placeholder, 
textarea::placeholder {
  color: var(--font-p);
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: black;
  outline: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.contact-form button {
  display: block;
  width: 25%;
  padding: 10px;
  background-color: var(--accent);
  color: var(--font-s);
  border-color: var(--secondary);
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  margin: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
  
.contact-form button:hover {
  background-color: var(--secondary);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}


/* Contact Card */

p {
  margin: 0;
}

.contact-card {
  width: 85%;
  height: 65%;
  z-index: 100;
  position: fixed;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--secondary);

}

.close-icon {
  position: absolute; 
  top: 5%; 
  right: 3%; 
  color: #333;
  cursor: pointer;
}

.contact-detail{
  color: var(--font-s);
  text-align: center;
  margin: 2em 0 5% 0;
}

.contact-detail a, p {
  margin: 2% 0 0 0;
}